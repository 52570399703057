import React, { useEffect, useState } from 'react';
import styles from '@styles/ResetPwdPage.module.css';
import { useNavigate } from 'react-router-dom';
import * as config from '../config';
import Footer from '../components/Footer';
import { Alert, Form, Button, Input } from 'antd';
import { UserOutlined, LockOutlined, ArrowLeftOutlined } from '@ant-design/icons';

const controller = new AbortController();

function ResetPwdPage() {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [form] = Form.useForm();

    useEffect(() => {
        return () => {
            // cleanup
            if (submitting) {
                controller.abort();
            }
        };
    }, []);

    const onFinish = async (values) => {
        const req = {
            email: values.email,
            password: values.password,
            repassword: values.repassword,
        };
        // console.log(req);
        setSuccessMsg('');
        // setErrMsg('');
        setSubmitting(true);
        try {
            const response = await fetch(`${config.apiURL}/password`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(req),
                signal: controller.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to reset password');
            }
            const data = await response.json();
            setSuccessMsg(data.reply);
            form.resetFields();
        } catch (error) {
            // console.log('Error:', error);
            setErrMsg(error.message);
        } finally {
            setSubmitting(false);
        }
    };

    const Success = () => (
        <div className={styles.container}>
            <div className={styles.content_container}>
                <Alert message="Success" description={successMsg} type="success" showIcon />
                <br />
                <Button onClick={() => navigate('/')} type="primary">
                    返回登录
                </Button>
            </div>
        </div>
    );

    if (successMsg) {
        return <Success />;
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.content_container}>
                    <Button onClick={() => navigate('/')} type="text" size="small">
                        <ArrowLeftOutlined />
                        返回
                    </Button>
                    <h3>密码重置</h3>
                    {!!errMsg && (
                        <>
                            <Alert message={errMsg} type="error" closable onClose={() => setErrMsg()} />
                            <br />
                        </>
                    )}
                    <Form form={form} autoComplete="off" autoCapitalize="off" autoCorrect="off" onFinish={onFinish}>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined />} autoComplete="off" placeholder="用户名(邮箱)" />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your new password',
                                },
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined />} autoComplete="off" placeholder="新密码" />
                        </Form.Item>

                        <Form.Item
                            name="repassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your new password',
                                },
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined />} autoComplete="off" placeholder="确认新密码" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={submitting} block>
                                Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ResetPwdPage;
